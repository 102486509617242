<template>
  <div id="TaskBar">
    <!-- <div class="getBack"
         @click="$router.back(-1)">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div> -->
    <!--航线区-->
    <div class="airRouteInfoBox" v-if="TaskType == 'airroute'">
      <!--显示当前航线数据-->
      <!-- <h3>航线信息</h3> -->
      <div class="airrouteDataListBox" v-if="this.TaskData.waypoint">
        <div class="airrouteData">
          <h1>
            航线名称 :<span>{{ TaskData.filetitle }}</span>
          </h1>
        </div>
        <div class="airrouteData">
          <h1>
            创建日期 :<span>{{ TaskData.data_create_time }}</span>
          </h1>
        </div>
        <div class="airrouteData" v-if="TaskData.data_update_time">
          <h1>
            更新日期 :<span>{{ TaskData.data_update_time }}</span>
          </h1>
        </div>
        <div class="airrouteData">
          <h1>
            航点数 :<span>{{ TaskData.waypoint.length }}</span>
          </h1>
        </div>
        <div class="airrouteData">
          <h1>
            飞行速度 :<span
              >{{ TaskData.airrouteset[0].setMissionSpeed }}(m/s)</span
            >
          </h1>
        </div>
        <div class="airrouteData">
          <h1>
            飞行高度 :<span
              >{{ TaskData.airrouteset[0].setMissionHeight }}(m)</span
            >
          </h1>
        </div>
      </div>
    </div>
    <!--建图区-->
    <div class="getBack" v-if="TaskType == 'figure'" @click="$router.back(-1)">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div>
    <div class="OrthophotoInfoBox" v-if="TaskType == 'figure'">
      <!--显示当前建图数据-->
      <div
        class="selectDisplayContent"
        style="display: flex; justify-content: center; margin: 20px 0 40px 0"
        v-if="MapTypeShou"
      >
        <RadioGroup v-model="MapType" type="button">
          <Radio label="正射地图" :disabled="OrthophotoDisabled"></Radio>
          <Radio label="三维模型" :disabled="modelDisabled"></Radio>
        </RadioGroup>
      </div>
      <!-- <h3>建图信息 :</h3> -->
      <div class="OrthophotoDataListBox" v-if="this.TaskData.local_data">
        <div class="OrthophotoData">
          <h1>
            建图名称 :<span>{{ TaskData.filetitle }}</span>
          </h1>
        </div>
        <div class="OrthophotoData">
          <h1>
            创建日期 :<span>{{ TaskData.data_create_time }}</span>
          </h1>
        </div>
        <div class="OrthophotoData" v-if="TaskData.data_update_time">
          <h1>
            更新日期 :<span>{{ TaskData.data_update_time }}</span>
          </h1>
        </div>
        <div class="OrthophotoData">
          <h1>
            面积(m²)：<span>{{ TaskData.figure_area }}</span>
          </h1>
        </div>
        <div class="OrthophotoData">
          <h1>
            飞行时间 :<span>{{
              TaskData.flytime | formatSecondsInChinese
            }}</span>
          </h1>
        </div>
        <div class="OrthophotoData">
          <h1>
            飞行速度:<span>{{ TaskData.fly_speed }}(m/s)</span>
          </h1>
        </div>
        <div class="OrthophotoData">
          <h1>
            飞行高度:<span>{{ TaskData.fly_height }}(m)</span>
          </h1>
        </div>
      </div>

      <!-- <h1 style="text-align: center; font-size: 20px; margin: 20px 0; color: #999"
          v-if="!MapTypeShou">
        此建图暂无正射和模型
      </h1>

      <div class="upData">
        <h1 @click="OrthophotoUpLoad = !OrthophotoUpLoad">上传正射</h1>
        <br />
      </div> -->
    </div>
    <!--弹框区-->
    <!--显示图片展示-->
    <Modal
      v-model="PictureLook"
      fullscreen
      footer-hide
      class-name="PictureLook-center-modal"
      @on-cancel="closePictureLook"
    >
      <div slot="header">
        <div class="header" style="height: 30px">
          <i
            v-show="contrast"
            class="ivu-icon ivu-icon-md-swap contrastIcon"
            @click="pictureShow = !pictureShow"
          ></i>
          <p style="text-align: center">
            {{ TaskData.filetitle }} - 航点{{ presentAirlineOrder + 1 }}
          </p>
        </div>
      </div>
      <div class="content">
        <!--图片展示-->
        <div class="PictureShow" v-if="pictureShow">
          <div class="SelectTime">
            <Select v-model="SelectDate1" style="width: 200px">
              <Option v-for="(item, idx) in DateList" :value="idx" :key="idx"
                >{{ dateFormat(item) }}
              </Option>
            </Select>
          </div>
          <!--大图展示-->
          <div>
            <div class="PictureBox">
              <div class="LastPicture" @click="LastPicture">
                <i
                  class="ivu-icon ivu-icon-ios-arrow-back"
                  style="font-size: 60px"
                ></i>
              </div>
              <div>
                <div class="Picture">
                  <img
                    :src="showImg1"
                    @mousewheel="zoomimgBig($event)"
                    id="ScrollImg-2"
                  />
                </div>
                <p style="textalign: center; height: 21px">{{ remark1 }}</p>
              </div>
              <div class="NextPicture" @click="NextPicture">
                <i
                  class="ivu-icon ivu-icon-ios-arrow-forward"
                  style="font-size: 60px; margin-right: 15px"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <!--图片对比-->
        <div class="PictureContrast" v-if="!pictureShow">
          <div class="ContrastBox">
            <!--最新图片展示-->
            <div class="rightPicture">
              <div class="SelectTime">
                <Select v-model="SelectDate2" style="width: 200px">
                  <Option
                    v-for="(item, idx) in DateList"
                    :value="idx"
                    :key="idx"
                    >{{ dateFormat(item) }}
                  </Option>
                </Select>
              </div>
              <div class="ContrastMiddle">
                <div class="MiddleBox">
                  <div class="Last">
                    <i
                      class="ivu-icon ivu-icon-ios-arrow-up"
                      @click="LastPicture2"
                    ></i>
                  </div>
                  <div class="Picture">
                    <img
                      :src="showImg2"
                      @mousewheel="zoomimg($event)"
                      id="ScrollImg"
                    />
                  </div>
                  <div class="Next">
                    <i
                      class="ivu-icon ivu-icon-ios-arrow-down"
                      @click="NextPicture2"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <!--往期图片展示-->
            <div class="leftPicture">
              <div class="SelectTime">
                <Select v-model="SelectDate3" style="width: 200px">
                  <Option
                    v-for="(item, idx) in DateList"
                    :value="idx"
                    :key="idx"
                    >{{ dateFormat(item) }}
                  </Option>
                </Select>
              </div>
              <div class="ContrastMiddle">
                <div class="MiddleBox">
                  <div class="Last">
                    <!--<i-->
                    <!--class="ivu-icon ivu-icon-ios-arrow-up"-->
                    <!--@click=""-->
                    <!--&gt;</i>-->
                  </div>
                  <div class="Picture">
                    <img
                      :src="showImg3"
                      @mousewheel="zoomimg($event)"
                      id="ScrollImg-1"
                    />
                  </div>
                  <div class="Next">
                    <!--<i-->
                    <!--class="ivu-icon ivu-icon-ios-arrow-down"-->
                    <!--@click=""-->
                    <!--&gt;</i>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!--正射地图对比展示-->
    <Modal
      v-model="OrthophotoLook"
      fullscreen
      footer-hide
      class-name="OrthophotoLook-center-modal"
      @on-cancel="closeOrthophotoLook"
    >
      <div slot="header">
        <div class="header">
          <div style="margin-right: 10px">
            <RadioGroup v-model="MapType" type="button">
              <Radio label="正射地图" :disabled="OrthophotoDisabled"></Radio>
              <Radio label="三维模型" :disabled="modelDisabled"></Radio>
            </RadioGroup>
          </div>
          <div
            v-show="contrast2"
            style="
              width: 1px;
              height: 70%;
              background-color: #ddd;
              margin: 0 30px;
            "
          ></div>
          <i
            v-show="contrast2"
            class="ivu-icon ivu-icon-md-swap contrastIcon"
            style="font-size: 30px"
            @click="OrthophotoShow = !OrthophotoShow"
          ></i>
        </div>
      </div>
      <div class="Orthophotocontent" v-show="!modelShou">
        <div class="OrthophotoBox" v-show="OrthophotoShow">
          <div class="SelectTime" style="z-index: 10000">
            <Select v-model="SelectDate4" style="width: 200px">
              <Option
                v-for="(item, ind) in OrthophotoDateList"
                :value="item.value"
                :key="ind"
                >{{ item.value }}
              </Option>
            </Select>
          </div>
          <div class="OrthophotoMap" id="OrthophotoMap"></div>
        </div>
        <div class="OrthophotoContrast" v-show="!OrthophotoShow">
          <!--最新正射展示-->
          <div class="leftOrthophoto" id="leftOrthophoto">
            <div class="SelectTime" style="z-index: 10000">
              <Select v-model="SelectDate5" style="width: 200px">
                <Option
                  v-for="(item, ind) in OrthophotoDateList"
                  :value="item.value"
                  :key="ind"
                  >{{ item.value }}
                </Option>
              </Select>
            </div>
            <div class="ContrastMiddle" id="ContrastMiddle1"></div>
          </div>
          <!--往期正射展示-->
          <div class="rightOrthophoto" id="rightOrthophoto">
            <div class="SelectTime" style="z-index: 10000">
              <Select v-model="SelectDate6" style="width: 200px">
                <Option
                  v-for="(item, ind) in OrthophotoDateList"
                  :value="item.value"
                  :key="ind"
                  >{{ item.value }}
                </Option>
              </Select>
            </div>
            <div class="ContrastMiddle" id="ContrastMiddle2"></div>
          </div>
        </div>
      </div>
      <div class="modelContent" id="modelContent" v-show="modelShou"></div>
    </Modal>
    <!--上传正射-->
    <Modal
      v-model="OrthophotoUpLoad"
      class-name="OrthophotoUpLoad-modal"
      footer-hide
    >
      <p slot="header" style="text-align: center">
        <span style="font-size: 20px">上传正射</span>
      </p>
      <div class="content" style="padding: 0 40px">
        <Form ref="addUav" :model="OrthophotoUpLoadData" label-position="top">
          <FormItem label="地图名称 (必填)" prop="device_name">
            <Input v-model="OrthophotoUpLoadData.mapname"></Input>
          </FormItem>
          <!--<FormItem label="拍摄日期 (必填)" prop="device_name">-->
          <!--<Input v-model="OrthophotoUpLoadData.mapdate"></Input>-->
          <!--</FormItem>-->
          <FormItem label="上传正射ZIP文件" prop="device_name">
            <Upload
              ref="OrUpLoad"
              :data="OrthophotoUpLoadData"
              name="zip"
              :disabled="Upload1Disabled"
              :show-upload-list="true"
              :format="['zip', 'ZIP']"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :on-success="OrthophotoSuccess"
              :on-error="OrthophotoError"
              multiple
              type="drag"
              :action="Upload1URL"
              style="display: inline-block; width: 100%"
            >
              <div style="padding: 20px 0; width: 100%">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p style="font-size: 20px">点击或将文件拖动到这里进行上传</p>
                <p>(必须填写上方信息才可进行上传)</p>
              </div>
            </Upload>
          </FormItem>
        </Form>
      </div>
    </Modal>

    <!--上传模型-->
    <Modal v-model="UpLoad3D" class-name="OrthophotoUpLoad-modal" footer-hide>
      <p slot="header" style="text-align: center">
        <span style="font-size: 20px">上传三维模型</span>
      </p>
      <div class="content" style="padding: 0 40px">
        <Form ref="addUav" :model="UpLoad3DData" label-position="top">
          <FormItem label="模型名称 (必填)" prop="device_name">
            <Input v-model="UpLoad3DData.modelname"></Input>
          </FormItem>
          <FormItem label="上传模型ZIP文件" prop="device_name">
            <Upload
              ref="OrUpLoad"
              :data="UpLoad3DData"
              name="zip"
              :disabled="Upload2Disabled"
              :show-upload-list="true"
              :format="['zip', 'ZIP']"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :on-success="Success3D"
              :on-error="Error3D"
              multiple
              type="drag"
              :action="Upload2URL"
              style="display: inline-block; width: 100%"
            >
              <div style="padding: 20px 0; width: 100%">
                <Icon
                  type="ios-cloud-upload"
                  size="52"
                  style="color: #3399ff"
                ></Icon>
                <p style="font-size: 20px">点击或将文件拖动到这里进行上传</p>
                <p>(必须填写上方信息才可进行上传)</p>
              </div>
            </Upload>
          </FormItem>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
// import routePoint from "@/assets/img/statistics/routePoint.png";
import filterMixin from '@/utils/filter.mixin.js'

export default {
  mixins: [filterMixin],
  name: 'TaskBar',
  props: {
    shouAirRoute: {
      type: Function,
      default: null,
    },
    // shou3DAirRoute: {
    //   type: Function,
    //   default: null,
    // },
    // close3DMap: {
    //   type: Function,
    //   default: null,
    // },
  },
  data: function () {
    return {
      user_info: {},
      userType: 1,
      Project2info: {},
      TaskType: 'airroute',
      TaskData: {},
      PictureLook: false, //图片查看弹框
      pictureShow: true,
      contrast: false, //切换图标是否显示
      presentAirlineOrder: '', //当前点击的航点

      ////////////////////////

      DateList: [], // 拍照时间线
      imgListPoint: [], // 拍照时间对应的航点图片
      // routePoint, // 航点默认图片
      routeNum: 1, // 航点数,暨航次图片数

      //////////////////////////

      airlineData: [], //整条航线数据
      //大图展示时间选择器
      SelectDate1: '', //默认显示数据
      showImg1: '',
      remark1: '',
      //对比不同时间同航点的照片
      SelectDate2: '', //图一默认显示最新日期
      showImg2: '',
      SelectDate3: '', //图二默认显示最早日期
      showImg3: '',
      //建图区
      MapType: '', //选择了哪个地图显示
      MapTypeShou: false,
      OrthophotoData: [], //正射模型数据
      OrthophotoLook: false,
      contrast2: true, //正射地图对比icon显示
      OrthophotoDisabled: false,
      modelDisabled: false,

      SelectDate4: '', //正射时间
      SelectDate5: '',
      SelectDate6: '',
      OrthophotoShow: true, //显示正射地图或对比地图
      OrthophotoDateList: [],
      modelList: [],
      map1: null,
      map2: null,
      map3: null,
      modelShou: false,
      googleLayer1: null,
      imageLayerList1: [],
      GetMapDetail1: null,
      googleLayer2: null,
      imageLayerList2: [],
      GetMapDetail2: null,
      googleLayer3: null,
      imageLayerList3: [],
      GetMapDetail3: null,
      // 上传正射
      OrthophotoUpLoad: false,
      OrthophotoUpLoadData: {
        mapname: '',
        mapdate: '',
        mapcount: '',
        maparea: '',
        // pm_first_id: '',
        // pm_second_id: '',
        bf_id: '',
        token: '',
      },
      Upload1Disabled: true,
      Upload1URL: '',
      // 上传三维模型
      UpLoad3D: false,
      UpLoad3DData: {
        modelname: '',
        modelarea: '',
        // pm_first_id: '',
        // pm_second_id: '',
        bf_id: '',
        token: '',
      },
      Upload2Disabled: true,
      Upload2URL: '',
    }
  },
  mounted() {
    // this.LDResource()
    var _this = this
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
    this.userType = sessionStorage.getItem('userType')
    this.TaskData = JSON.parse(sessionStorage.getItem('getTaskData'))
    this.TaskType = this.TaskData.tasktype
    this.Project2info = JSON.parse(sessionStorage.getItem('Project2info'))
    console.log(this.TaskData)
    let centerPoint = [
       this.TaskData.waypoint[0].lng,
       this.TaskData.waypoint[0].lat
    ]
    this.$store.commit('SET_PitchMapCenter', centerPoint)
    setTimeout(() => {
      if (this.TaskType == 'airroute') {
        //点击航线
        _this.shouAirRoute(this.TaskData, '航线')
        // _this.shou3DAirRoute(this.TaskData, '航线');
      } else if (this.TaskType == 'figure') {
        //点击建图
        _this.shouAirRoute(this.TaskData, '建图')
        // this.$post(Api.bfOrthoModel(), {
        //   bf_id: this.TaskData.id,
        // }).then((res) => {
        //   console.log(res);
        //   if (res.code == 1) {
        //     if (res.data == undefined) {
        //       this.MapTypeShou = false;
        //     } else {
        //       this.OrthophotoData = res.data;
        //       var gather = [];
        //       res.data.forEach((item) => {
        //         gather.push(item.type);
        //       });
        //       if (gather.length == 0) {
        //         _this.MapTypeShou = false;
        //         return;
        //       }
        //       if (gather.includes(1)) {
        //         _this.MapType = '正射地图';
        //       } else {
        //         _this.OrthophotoDisabled = true;
        //       }
        //       if (!gather.includes(2)) {
        //         _this.modelDisabled = true;
        //       }
        //       this.MapTypeShou = true;
        //     }
        //   } else {
        //     this.$Message.error(res.msg_customer);
        //   }
        // });
      }
    }, 80)

    //正射
    this.Upload1URL = Api.getOrthophotoUpDataUrl()
    this.OrthophotoUpLoadData = {
      token: sessionStorage.getItem('token'),
      mapname: '',
      mapdate: this.$moment(new Date()).format('YYYY-MM-DD'),
      mapcount: this.TaskData.imgcount, //照片数量
      maparea: this.TaskData.figure_area, //地图面积
      team_id: this.user_info.team_id,
      bf_id: this.TaskData.id, //任务id
    }
    //模型
    this.Upload2URL = Api.get3DUpDataUrl()
    this.UpLoad3DData = {
      token: sessionStorage.getItem('token'),
      modelname: '',
      modelarea: this.TaskData.figure_area, //地图面积
      team_id: this.user_info.team_id,
      bf_id: this.TaskData.id, //任务id
    }

    //点击航点显示相应的照片
    this.$_bus.$off('Into_Picture')
    this.$_bus.$on('Into_Picture', function (e) {
      _this.Into_Picture(e)
    })
    //点击建图显示相应的正射地图
    this.$_bus.$off('Into_OrthophotoMap')
    this.$_bus.$on('Into_OrthophotoMap', function (e) {
      _this.Into_OrthophotoMap(e)
    })
  },
  methods: {
    //获取一张图坐标点
    LDResource() {
      let that = this
      this.$post(Api.loadAllResource(), {
        team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
        type: 0,
        bf_name: '',
        page: 1,
        limit: 100,
      })
        .then((res) => {
          console.log('成功')
          this.TaskData = res.data.data[0].air_line.tasklist
          console.log('this.TaskData', this.TaskData)
          this.TaskType = this.TaskData.tasktype
          // that.initModalMark(
          //   res.data.data[0].data_list,
          //   res.data.data[0].air_line.tasklist
          // )
        })
        .catch((err) => {
          console.log('失败')
          // console.log('err=====',err);
          // this.$Message.error(err)
        })
      // this.$post(PanoramawebList(), {
      //   page: 1,
      //   limit: 100,
      //   team_id: JSON.parse(sessionStorage.getItem('user_info')).team_id,
      // })
      //   .then((res) => {
      //   })
      //   .catch((err) => {
      //     // console.log('err=====',err);
      //     // this.$Message.error(err)
      //   })
    },
    //====大图展示区======
    //加载航线中航点的照片
    Into_Picture(e) {
      let air_route_id = e.DATA.id
      this.routeNum = e.DATA.waypoint.length
      this.presentAirlineOrder = e.DATA.waypoint[e.ind].order //选中的航点索引值
      console.log('1', this.SelectDate1)

      this.$post(Api.airRouteMedia(), {
        air_route_id: air_route_id,
      }).then((res) => {
        // console.log(res.data);
        if (res.code == 1) {
          //   this.airlineData = res.data.img_list;
          // console.log('加载航线中航点的照片', res.data.img_list);
          if (
            JSON.stringify(res.data.img_list) !== '{}' &&
            JSON.stringify(res.data.img_list) !== '[]'
          ) {
            this.PictureLook = true
            this.contrast = true

            this.DateList = [] // 拍照时间线
            this.imgListPoint = [] // 拍照时间对应的航点图片

            res.data.img_list.forEach((e) => {
              this.DateList.unshift(e.img_list_point[0].createTime)
              this.imgListPoint.unshift(e.img_list_point)
            })
            console.log('this.DateList', this.DateList)
            // console.log(this.DateList);
            // this.imgListPoint.forEach((ele) => {
            //   let l = this.routeNum - ele.length;
            //   console.log("l", l);
            //   if (l) {
            //     for (let i = 0; i < l; i++) {
            //       ele.push({ path: this.routePoint });
            //     }
            //   }
            // });
            //////////////////////////////////////////////////////////////////
            // 大图默认选最新一天
            this.SelectDate1 = this.DateList.length - 1
            this.SelectDate2 = this.DateList.length - 1
            console.log('2', this.SelectDate1)

            //对比模块,显示最旧一天
            if (this.DateList.length > 1) {
              this.SelectDate3 = 0
            } else {
              this.contrast = false //切换图标隐藏
            }
          } else {
            this.$Message.error('此航线暂无照片')
          }
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 上一个航点的照片
    LastPicture() {
      if (this.presentAirlineOrder === 0) {
        this.$Message.error('已是第一个航点')
      } else {
        this.presentAirlineOrder = this.presentAirlineOrder - 1
        this.showImg1 =
          this.imgListPoint[this.SelectDate1][this.presentAirlineOrder].path
        this.remark1 =
          this.imgListPoint[this.SelectDate1][this.presentAirlineOrder].remarks
      }
    },
    // 下一个航点的照片
    NextPicture() {
      if (this.presentAirlineOrder >= this.routeNum - 1) {
        this.$Message.error('已是最后一个航点')
      } else {
        this.presentAirlineOrder = this.presentAirlineOrder + 1
        this.showImg1 =
          this.imgListPoint[this.SelectDate1][this.presentAirlineOrder].path
        this.remark1 =
          this.imgListPoint[this.SelectDate1][this.presentAirlineOrder].remarks
      }
    },
    //====图片对比区======
    // 上一个航点的照片
    LastPicture2() {
      if (this.presentAirlineOrder === 0) {
        this.$Message.error('已是第一个航点')
      } else {
        this.presentAirlineOrder = this.presentAirlineOrder - 1
        // this.airlineData.forEach((item) => {
        //   if (item.order == this.presentAirlineOrder) {
        //     this.showImg2 = item.image[this.SelectDate2][0];
        //     this.showImg3 = item.image[this.SelectDate3][0];
        //   }
        // });
        this.showImg2 =
          this.imgListPoint[this.SelectDate2][this.presentAirlineOrder].path
        this.showImg3 =
          this.imgListPoint[this.SelectDate3][this.presentAirlineOrder].path
      }
    },
    // 下一个航点的照片
    NextPicture2() {
      if (this.presentAirlineOrder >= this.routeNum - 1) {
        this.$Message.error('已是最后一个航点')
      } else {
        this.presentAirlineOrder = this.presentAirlineOrder + 1

        // this.airlineData.forEach((item) => {
        //   if (item.order == this.presentAirlineOrder) {
        //     this.showImg2 = item.image[this.SelectDate2][0];
        //     this.showImg3 = item.image[this.SelectDate3][0];
        //   }
        // });

        this.showImg2 =
          this.imgListPoint[this.SelectDate2][this.presentAirlineOrder].path
        this.showImg3 =
          this.imgListPoint[this.SelectDate3][this.presentAirlineOrder].path
      }
    },
    // 关闭航点图片查看弹框时清空数据
    closePictureLook() {
      this.pictureShow = true
      this.contrast = false //切换图标是否显示
      this.presentAirlineOrder = '' //当前点击的航点
      this.airlineData = [] //整条航线数据
      // 大图展示时间选择器
      this.DateList = []
      this.SelectDate1 = '' //默认显示数据
      this.showImg1 = ''
      this.remark1 = ''
      //对比不同时间同航点的照片
      this.SelectDate2 = '' //图一默认显示最新日期
      this.showImg2 = ''
      this.SelectDate3 = '' //图二默认显示最早日期
      this.showImg3 = ''
    },
    //滚轮控制大小
    zoomimg(event) {
      // console.log(event);
      var delta = 0
      if (!event) event = window.event
      if (event.wheelDelta) {
        delta = event.wheelDelta / 120
        if (window.opera) delta = -delta
      } else if (event.detail) {
        delta = -event.detail / 3
      }
      var img = document.getElementById('ScrollImg')
      var img = document.getElementById('ScrollImg-1')
      if (delta > 0) {
        var width = img.width
        $('#ScrollImg').css('width', width * 1.1)
        $('#ScrollImg-1').css('width', width * 1.1)
      } else if (delta < 0) {
        var width = img.width
        if (width > 400) {
          $('#ScrollImg').css('width', width * 0.9)
          $('#ScrollImg-1').css('width', width * 0.9)
        }
      }
    },
    zoomimgBig(event) {
      let delta = 0
      if (!event) event = window.event
      if (event.wheelDelta) {
        delta = event.wheelDelta / 120
        if (window.opera) delta = -delta
      } else if (event.detail) {
        delta = -event.detail / 3
      }
      let img = document.getElementById('ScrollImg-2')
      if (delta > 0) {
        var width = img.width
        $('#ScrollImg-2').css('width', width * 1.1)
      } else if (delta < 0) {
        let width = img.width
        if (width > 400) {
          $('#ScrollImg-2').css('width', width * 0.9)
        }
      }
    },
    // 打开正射模型显示弹框
    Into_OrthophotoMap(e) {
      let _this = this
      var location = e.DATA.local_data[0]
      if (this.MapTypeShou) {
        this.OrthophotoLook = true
        let OrthophotoList = [] //正射数据
        console.log('正射模型数据', this.OrthophotoData)
        this.OrthophotoData.forEach((item) => {
          if (item.type == 1) {
            //正射数据
            OrthophotoList.push(item)
          } else if (item.type == 2) {
            //模型数据
            this.modelList.push(item)
            // 创建3D地图=====================
            // 使用的Cesium提供的地图和地形数据，就必须申请账号和创建AccessToken
            Cesium.Ion.defaultAccessToken = Api.CesiumToken()
            //镜头默认范围(必须在viewer实例创建前设置)
            // 定位在中国上空
            Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(
              73.0,
              3.0,
              135.0,
              53.0
            )

            let viewer = new Cesium.Viewer('modelContent', {
              animation: false, //是否创建动画小器件，左下角仪表
              baseLayerPicker: false, //是否显示图层选择器
              fullscreenButton: false, //是否显示全屏按钮
              geocoder: false, //是否显示geocoder小器件，右上角查询按钮
              homeButton: true, //是否显示Home按钮
              infoBox: false, //是否显示信息框
              sceneModePicker: false, //是否显示3D/2D选择器
              selectionIndicator: false, //是否显示选取指示器组件
              timeline: false, //是否显示时间轴
              navigationHelpButton: false, //是否显示右上角的帮助按钮
              // 加载Cesium自带地图
              imageryProvider: new Cesium.TileMapServiceImageryProvider({
                url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
              }),
            })
            // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
            window.Viewer_figure = viewer
            // 去掉版权信息
            viewer.cesiumWidget.creditContainer.style.display = 'none'
            viewer.scene.globe.show = false //不显示地球，这条和地球透明度选一个就可以

            //天地图卫星影像
            // 加载本地地图=======================================
            if (!this.$online) {
              let arcgis_blue = viewer.imageryLayers.addImageryProvider(
                new Cesium.TileMapServiceImageryProvider({
                  // 有的版本是用createTileMapServiceImageryProvider
                  url: Api.TianDiTuMap(),
                  fileExtension: 'png',
                })
              )
            }
            // 开启地形深度检测
            viewer.scene.globe.depthTestAgainstTerrain = true

            // home定位到具体地点和高度(长沙)
            viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
              function (e) {
                e.cancel = true
                //你要飞的位置
                viewer.camera.flyTo({
                  destination: Cesium.Cartesian3.fromDegrees(
                    _this.$Center[0],
                    _this.$Center[1],
                    // 119.286808,
                    // 26.053644,
                    50000
                  ),
                })
              }
            )
          }
        })

        if (OrthophotoList.length == 0) {
          this.MapType = '三维模型'
          return
        } else {
          console.log(OrthophotoList)
          OrthophotoList.forEach((item) => {
            let shooting_date = item.path.path.split('/')
            this.OrthophotoDateList.push({
              value: item.create_time,
              maptype: item.path.maptype,
              shooting_date: shooting_date[shooting_date.length - 1],
              path: item.path.path,
              id: item.id,
            })
          })
          // 默认选最新一天
          this.SelectDate4 = this.OrthophotoDateList[0].value
          this.SelectDate5 = this.OrthophotoDateList[0].value
          // 引入瓦片图-平面
          let layer = []
          if (this.$online) {
            layer = [new AMap.TileLayer()]
          } else {
            layer = [
              new AMap.TileLayer({
                getTileUrl: function (x, y, z) {
                  return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
                },
                zIndex: 10,
              }),
            ]
          }
          //对比模块,显示最旧一天
          if (this.OrthophotoDateList.length > 1) {
            this.SelectDate6 =
              this.OrthophotoDateList[this.OrthophotoDateList.length - 1].value
            // 创建对比地图
            this.map2 = new AMap.Map('ContrastMiddle1', {
              resizeEnable: true,
              zoom: 15, //地图显示的缩放级别
              center: [location.lng, location.lat],
              layers: layer,
            })

            // 修改主题样式
            // if (this.$online) {
            //   this.map2.setMapStyle('amap://styles/whitesmoke');
            // }

            this.map3 = new AMap.Map('ContrastMiddle2', {
              resizeEnable: true,
              zoom: 15, //地图显示的缩放级别
              center: [location.lng, location.lat],
              layers: layer,
            })
            // 修改主题样式
            // if (this.$online) {
            //   this.map3.setMapStyle('amap://styles/whitesmoke');
            // }
          } else {
            this.contrast2 = false //切换图标隐藏
          }
          // 创建地图
          this.map1 = new AMap.Map('OrthophotoMap', {
            resizeEnable: true,
            expandZoomRange: true,
            zoom: 15, //地图显示的缩放级别
            center: [location.lng, location.lat],
          })
          xyzTileLayer.setMap(this.map1)
          // 修改主题样式
          //   if (this.$online) {
          //     this.map1.setMapStyle('amap://styles/whitesmoke');
          //   }
        }
      } else {
        this.$Message.error('此建图暂无正射和模型')
      }
    },
    //关闭正射模型弹框,注销地图,清空数据
    closeOrthophotoLook() {
      this.map1.destroy()
      this.map2.destroy()
      this.map3.destroy()
      this.map1 = null
      this.map2 = null
      this.map3 = null
      this.OrthophotoShow = true //显示正射地图或对比地图
      this.OrthophotoDateList = []
      this.modelList = []
      this.googleLayer1 = null
      this.imageLayerList1 = []
      this.GetMapDetail1 = null
      this.googleLayer2 = null
      this.imageLayerList2 = []
      this.GetMapDetail2 = null
      this.googleLayer3 = null
      this.imageLayerList3 = []
      this.GetMapDetail3 = null
      this.SelectDate4 = '' //正射时间
      this.SelectDate5 = ''
      this.SelectDate6 = ''
      window.Viewer_figure = null
      $('#modelContent').html('')
    },
    // 上传正射----------------------------
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择zip压缩包.',
      })
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于XXXX.',
      })
    },
    OrthophotoSuccess() {
      this.$Message.info('上传成功')
      this.OrthophotoUpLoad = false
      // 清空数据
      this.OrthophotoUpLoadData = {
        modelname: '',
        modelarea: this.TaskData.figure_area, //地图面积
        // pm_first_id: this.Project2info.pm_first_id,
        // pm_second_id: this.Project2info.pm_id,
        bf_id: this.TaskData.id, //任务id
      }
    },
    OrthophotoError(error) {
      this.$Message.error(error)
    },
    Success3D() {
      this.$Message.info('上传成功')
      this.UpLoad3D = false
      // 清空数据
      this.UpLoad3DData = {
        modelname: '',
        modelarea: this.TaskData.figure_area, //地图面积
        // pm_first_id: this.Project2info.pm_first_id,
        // pm_second_id: this.Project2info.pm_id,
        bf_id: this.TaskData.id, //任务id
      }
    },
    Error3D(error) {
      this.$Message.error(error)
    },

    //格式化时间 年-月-日 时:分:秒
    dateFormat(time) {
      const t = new Date(time)
      // 日期格式
      const format = 'Y-m-d h:i:s'
      const year = t.getFullYear()
      // 由于 getMonth 返回值会比正常月份小 1
      const month = t.getMonth() + 1
      const day = t.getDate()
      const hours = t.getHours()
      const minutes = t.getMinutes()
      const seconds = t.getSeconds()
      const hash = {
        Y: year,
        m: month,
        d: day,
        h: hours,
        i: minutes,
        s: seconds,
      }
      return format.replace(/\w/g, (o) => {
        return hash[o]
      })
    },
  },
  //   beforeDestroy() {
  //     //销毁时关闭并隐藏3D地图
  //     this.close3DMap();
  //   },
  watch: {
    //大图展示的时间改变,展示当前时间当前航点的图片
    SelectDate1(val) {
      console.log('SelectDate1', val)
      this.imgListPoint.forEach((item, idx) => {
        if (idx === val) {
          console.log(this.presentAirlineOrder)
          this.showImg1 = item[this.presentAirlineOrder].path
          this.remark1 = item[this.presentAirlineOrder].remarks
        }
      })
    },
    SelectDate2(val) {
      //图一默认显示最新日期
      console.log('SelectDate2', val)
      //   this.airlineData.forEach((item) => {
      //     if (item.order == this.presentAirlineOrder) {
      //       this.showImg2 = item.image[val][0];
      //     }
      //   });
      this.imgListPoint.forEach((item, idx) => {
        if (idx === val) {
          this.showImg2 = item[this.presentAirlineOrder].path
        }
      })
    },
    SelectDate3(val) {
      //图二默认显示最早日期
      console.log('SelectDate3', val)
      //   this.airlineData.forEach((item) => {
      //     if (item.order == this.presentAirlineOrder) {
      //       this.showImg3 = item.image[val][0];
      //     }
      //   });
      this.imgListPoint.forEach((item, idx) => {
        if (idx === val) {
          this.showImg3 = item[this.presentAirlineOrder].path
        }
      })
    },
    // 建图区
    MapType(val) {
      if (val == '三维模型') {
        this.modelShou = true
        this.contrast2 = false //切换图标隐藏
        // console.log(window.location.href.split("#")[0]);
        // console.log('服务器三维模型地址',this.modelList[0].path.split("file"));
        let locationHref = window.location.href.split('#')[0]
        let serverPath = this.modelList[0].path.split('file')[1]
        let modelPath = locationHref + 'file' + serverPath
        console.log('三维模型地址', modelPath)
        let viewer = window.Viewer_figure
        // 加载3DTileset===================================
        var tileset = viewer.scene.primitives.add(
          new Cesium.Cesium3DTileset({
            url: modelPath,
            maximumScreenSpaceError: 5, //最大的屏幕空间误差
            // maximumNumberOfLoadedTiles: 1000000000000000, //最大加载瓦片个数
          })
        )
        viewer.scene.primitives.add(tileset)
        tileset.readyPromise.then(function (tileset) {
          viewer.scene.primitives.add(tileset)
          var heightOffset = -10.0 //高度
          var boundingSphere = tileset.boundingSphere
          var cartographic = Cesium.Cartographic.fromCartesian(
            boundingSphere.center
          )
          var surface = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            0.0
          )
          var offset = Cesium.Cartesian3.fromRadians(
            cartographic.longitude,
            cartographic.latitude,
            heightOffset
          )
          var translation = Cesium.Cartesian3.subtract(
            offset,
            surface,
            new Cesium.Cartesian3()
          )
          tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
          viewer.zoomTo(
            tileset,
            new Cesium.HeadingPitchRange(
              2.5,
              -0.5,
              tileset.boundingSphere.radius * 1
            )
          )
        })
      } else {
        this.modelShou = false
        if (this.OrthophotoDateList.length > 1) {
          this.contrast2 = true
        }
      }
    },
    SelectDate4(val) {
      if (this.map1 == null) {
        return
      }
      var _this = this
      if (_this.GetMapDetail1 !== null) {
        this.map1.off('mousewheel', _this.GetMapDetail1) //移除地图事件
      }
      if (_this.imageLayerList1.length !== 0) {
        this.imageLayerList1.forEach((item) => {
          _this.map1.remove(item)
        })
      }
      if (_this.googleLayer1 !== null) {
        _this.map1.remove(_this.googleLayer1)
      }
      this.OrthophotoDateList.forEach((item) => {
        if (item.value == val) {
          if (item.maptype == 2) {
            _this.GetMapDetail1 = function () {
              if (_this.map1.getZoom() < 19) {
                _this
                  .$post(Api.mapDetail(), {
                    kml_id: item.id,
                    shooting_date: item.shooting_date,
                    zoom: _this.map1.getZoom(),
                  })
                  .then((res) => {
                    if (res.code == 1) {
                      if (res.data && res.data.length > 0) {
                        this.imageLayerList1 = []
                        res.data.forEach((ele, index) => {
                          var imageLayer1 = new AMap.ImageLayer({
                            url: ele.imagePath,
                            bounds: new AMap.Bounds(
                              [ele.west, ele.south],
                              [ele.east, ele.north]
                            ),
                            zooms: _this.map1.getZoom(),
                          })
                          _this.map1.add(imageLayer1)
                          _this.imageLayerList1.push(imageLayer1)
                        })
                      }
                    }
                  })
              }
            }
            _this.GetMapDetail1()
            this.map1.on('mousewheel', _this.GetMapDetail1)
          } else if (item.maptype == 1) {
            console.log('还未对接口', item)
            // this.googleLayer1 = new AMap.TileLayer({
            //   zIndex: 10,
            //   getTileUrl: function (x, y, z) {
            //     return item.path + '/' + z + '/' + x + '/' + y + '.png';
            //   },
            // });
            // this.googleLayer1.setMap(this.map1);
          }
        }
      })
    },
    SelectDate5(val) {
      if (this.map2 == null) {
        return
      }
      var _this = this
      if (_this.GetMapDetail2 !== null) {
        this.map2.off('mousewheel', _this.GetMapDetail2) //移除地图事件
      }
      if (_this.imageLayerList2.length !== 0) {
        this.imageLayerList2.forEach((item) => {
          _this.map2.remove(item)
        })
      }
      if (_this.googleLayer2 !== null) {
        _this.map2.remove(_this.googleLayer2)
      }
      this.OrthophotoDateList.forEach((item) => {
        if (item.value == val) {
          if (item.maptype == 2) {
            _this.GetMapDetail2 = function () {
              console.log('滚动有没有加载啊')
              if (_this.map2.getZoom() < 19) {
                _this
                  .$post(Api.mapDetail(), {
                    kml_id: item.id,
                    shooting_date: item.shooting_date,
                    zoom: _this.map2.getZoom(),
                  })
                  .then((res) => {
                    if (res.code == 1) {
                      if (res.data && res.data.length > 0) {
                        this.imageLayerList2 = []
                        res.data.forEach((ele, index) => {
                          var imageLayer2 = new AMap.ImageLayer({
                            url: ele.imagePath,
                            bounds: new AMap.Bounds(
                              [ele.west, ele.south],
                              [ele.east, ele.north]
                            ),
                            zooms: _this.map2.getZoom(),
                          })
                          _this.map2.add(imageLayer2)
                          _this.imageLayerList2.push(imageLayer2)
                        })
                      }
                    }
                  })
              }
            }
            _this.GetMapDetail2()
            this.map2.on('mousewheel', _this.GetMapDetail2)
          } else if (item.maptype == 1) {
            console.log('还未对接口')
            // this.googleLayer2 = new AMap.TileLayer({
            //   zIndex: 10,
            //   getTileUrl: function (x, y, z) {
            //     return item.path + '/' + z + '/' + x + '/' + y + '.png';
            //   },
            // });
            // this.googleLayer2.setMap(this.map2);
          }
        }
      })
    },
    SelectDate6(val) {
      if (this.map3 == null) {
        return
      }
      var _this = this
      if (_this.GetMapDetail3 !== null) {
        this.map3.off('mousewheel', _this.GetMapDetail3) //移除地图事件
      }
      if (_this.imageLayerList3.length !== 0) {
        this.imageLayerList3.forEach((item) => {
          _this.map3.remove(item)
        })
      }
      if (_this.googleLayer3 !== null) {
        _this.map3.remove(_this.googleLayer3)
      }
      this.OrthophotoDateList.forEach((item) => {
        if (item.value == val) {
          if (item.maptype == 2) {
            _this.GetMapDetail3 = function () {
              console.log('滚动有没有加载啊')
              if (_this.map3.getZoom() < 19) {
                _this
                  .$post(Api.mapDetail(), {
                    kml_id: item.id,
                    shooting_date: item.shooting_date,
                    zoom: _this.map3.getZoom(),
                  })
                  .then((res) => {
                    if (res.code == 1) {
                      if (res.data && res.data.length > 0) {
                        this.imageLayerList3 = []
                        res.data.forEach((ele, index) => {
                          var imageLayer3 = new AMap.ImageLayer({
                            url: ele.imagePath,
                            bounds: new AMap.Bounds(
                              [ele.west, ele.south],
                              [ele.east, ele.north]
                            ),
                            zooms: _this.map3.getZoom(),
                          })
                          _this.map3.add(imageLayer3)
                          _this.imageLayerList3.push(imageLayer3)
                        })
                      }
                    }
                  })
              }
            }
            _this.GetMapDetail3()
            this.map3.on('mousewheel', _this.GetMapDetail3)
          } else if (item.maptype == 1) {
            console.log('还未对接口')
            // this.googleLayer3 = new AMap.TileLayer({
            //   zIndex: 10,
            //   getTileUrl: function (x, y, z) {
            //     return item.path + '/' + z + '/' + x + '/' + y + '.png';
            //   },
            // });
            // this.googleLayer3.setMap(this.map3);
          }
        }
      })
    },
    OrthophotoUpLoadData: {
      deep: true,
      handler: function (val) {
        if (val.mapname !== '' && val.mapdate !== '') {
          this.Upload1Disabled = false
        } else {
          this.Upload1Disabled = true
        }
      },
    },
    UpLoad3DData: {
      deep: true,
      handler: function (val) {
        if (val.modelname !== '') {
          this.Upload2Disabled = false
        } else {
          this.Upload2Disabled = true
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
#TaskBar {
  // padding-top: 50px;

  .getBack {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    color: $white;
    padding: 5px 0;
    box-sizing: content-box;
    border-bottom: 1px solid $font-color-1;
    i {
      font-size: 25px;
      margin-right: 6px;
    }

    span {
      font-size: 18px;
      color: $white;
    }
  }

  .airRouteInfoBox {
    width: 100%;
    height: 100%;
    padding: 15px 10px;

    h3 {
      font-size: 26px;
      color: $white;
      margin-bottom: 10px;
    }

    .airrouteDataListBox {
      .airrouteData {
        width: 100%;
        padding: 10px 0;
        // border-bottom: 1px solid #ddd;

        h1 {
          font-size: 16px;
          color: $white;
          margin: 5px 0;
          span {
            color: $font-color-1;
            margin-left: 5px;
            font-size: 14px;
          }
        }

        // h2 {
        //   color: $white;
        // }
      }
    }
  }

  .OrthophotoInfoBox {
    width: 100%;
    height: 100%;
    padding: 15px 10px;

    h3 {
      font-size: 26px;
      color: $white;
      margin-bottom: 10px;
    }

    .OrthophotoDataListBox {
      margin-bottom: 20px;

      .OrthophotoData {
        width: 100%;
        padding: 8px 0;
        // border-bottom: 1px solid #ddd;

        h1 {
          font-size: 16px;
          color: $white;
          margin: 5px 0;
          span {
            color: $font-color-1;
            margin-left: 5px;
            font-size: 14px;
          }
        }

        // h2 {
        //   color: $white;
        // }
      }
    }

    .upData {
      text-align: center;

      h1 {
        display: inline-block;
        font-size: 16px;
        width: 100px;
        height: 40px;
        line-height: 38px;
        color: #409eff;
        cursor: pointer;
        background: rgba(64, 158, 255, 0.2);
        border: 1px solid rgba(63, 157, 255, 1);
        border-radius: 6px;
        margin-bottom: 10px;
      }
    }
  }
}

/deep/ .PictureLook-center-modal {
  .header {
    display: flex;
    align-items: center;

    .contrastIcon {
      font-size: 30px;
      cursor: pointer;
    }
  }

  .content {
    .PictureShow {
      .PictureBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .LastPicture,
        .NextPicture {
          height: 780px;
          width: 80px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .Picture {
          width: 1080px;
          height: 770px;
          cursor: pointer;
          background-color: #666666;
          margin: 0 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          > img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .PictureContrast {
      .ContrastBox {
        display: flex;
        justify-content: space-around;
        margin: 15px 0;

        .rightPicture {
          width: 740px;
          height: 810px;
        }

        .leftPicture {
          width: 740px;
          height: 810px;
        }

        .ContrastMiddle {
          .MiddleBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .Last {
              height: 60px;
              width: 720px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;

              > i {
                font-size: 60px;
              }
            }

            .Next {
              height: 60px;
              width: 720px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;

              > i {
                font-size: 60px;
              }
            }

            .Picture {
              width: 770px;
              height: 540px;
              cursor: pointer;
              background-color: #666666;
              margin: 0 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;

              > img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }

    .SelectTime {
      display: flex;
      justify-content: center;
      margin: 15px 0;
    }
  }
}

/deep/ .OrthophotoLook-center-modal {
  .header {
    height: 30px;
    display: flex;
    align-items: center;
  }

  .Orthophotocontent {
    width: 100%;
    height: 100%;

    .OrthophotoBox {
      width: 100%;
      height: 100%;
      position: relative;

      .SelectTime {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .OrthophotoMap {
        width: 100%;
        height: 100%;
        background-color: #999999;
      }
    }

    .OrthophotoContrast {
      width: 100%;
      height: 100%;
      display: flex;

      .rightOrthophoto {
        width: 50%;
        height: 100%;
        position: relative;

        .SelectTime {
          position: absolute;
          top: 10px;
          left: 10px;
        }

        .ContrastMiddle {
          width: 100%;
          height: 100%;
          background-color: #d3d3d3;
        }
      }

      .leftOrthophoto {
        width: 50%;
        height: 100%;
        position: relative;

        .SelectTime {
          position: absolute;
          top: 10px;
          left: 10px;
        }

        .ContrastMiddle {
          width: 100%;
          height: 100%;
          background-color: #d3d3d3;
        }
      }
    }
  }

  .modelContent {
    width: 100%;
    height: 100%;
    background-color: #cccccc;
  }
}
</style>
